import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class WhatsappService {
	constructor() {}

	/**
	 * Construye el mensaje de WhatsApp con los datos del freight.
	 * @param freight Objeto que contiene la información necesaria.
	 * @returns {string} Mensaje formateado para WhatsApp.
	 */
	buildWhatsAppMessage(freight: any): string {
		const origin = `${freight.origin.city}, ${freight.origin.state}.`;
		const destination = `${freight.destination.city}, ${freight.destination.state}.`;
		const profileUrl = `${window.location.origin}/company/ratings?uuid=${freight.company.uuid}`;

		return `Buen Día, soy la empresa *${freight.company.name}*, estoy interesado en la carga de *${origin}* a *${destination}* con el número de orden *${freight.orderId}*. El perfil de mi negocio es el siguiente: ${profileUrl}`;
	}

	/**
	 * Formatea un número de teléfono eliminando caracteres no numéricos.
	 * @param phone {string} Número de teléfono original.
	 * @returns {string} Número de teléfono formateado.
	 */
	formatPhoneNumber(phone: string): string {
		return '+52' + phone.replace(/[^+\d]/g, ''); // Conserva solo números y el prefijo "+"
	}

	/**
	 * Crea la URL de WhatsApp con el mensaje codificado.
	 * @param phoneNumber {string} Número de teléfono del destinatario.
	 * @param message {string} Mensaje a enviar.
	 * @returns {string} URL lista para WhatsApp.
	 */
	createWhatsAppUrl(phoneNumber: string, message: string): string {
		return `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
	}

	/**
	 * Redirige al usuario a la aplicación de WhatsApp.
	 * @param url {string} URL de WhatsApp generada.
	 */
	redirectToWhatsApp(url: string): void {
		window.open(url, '_blank');
	}
}

